// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/app/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("/app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-300-meter-js": () => import("/app/src/pages/cases/300meter.js" /* webpackChunkName: "component---src-pages-cases-300-meter-js" */),
  "component---src-pages-cases-deepconnect-js": () => import("/app/src/pages/cases/deepconnect.js" /* webpackChunkName: "component---src-pages-cases-deepconnect-js" */),
  "component---src-pages-cases-jassbot-js": () => import("/app/src/pages/cases/jassbot.js" /* webpackChunkName: "component---src-pages-cases-jassbot-js" */),
  "component---src-pages-cases-schoolofai-js": () => import("/app/src/pages/cases/schoolofai.js" /* webpackChunkName: "component---src-pages-cases-schoolofai-js" */),
  "component---src-pages-downloads-js": () => import("/app/src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-kontakt-js": () => import("/app/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-leistungen-js": () => import("/app/src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-projekte-js": () => import("/app/src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */)
}

exports.data = () => import("/app/.cache/data.json")

